<template>
    <div style="height: inherit; position: relative; width: 100%;">
        <b-card>
            <div class="m-1">
                <b>App User Manual Page</b>
            </div>
            <div v-for="num of imgUrls" :key="num.id">
                <Pic :num="num.id"></Pic>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BImg,
    BCard,
} from 'bootstrap-vue'

import Pic from './Pic.vue'

export default {
    props: {
        num: {
            type: Number,
        }
    },
    data() {
        return {
            width: 100,
            height: 100,
            imgUrls: [
                {id: 1},
                {id: 2},
                {id: 3},
                {id: 4},
            ]
        }
    },
    components: {
        BCard,
        BImg,
        Pic
    },
    created() {
        /* window.addEventListener("resize", () => {
            console.log(window.innerWidth)
            if(window.innerWidth > 600) this.width = 45
            else this.width = 100
        }); */
    },
    mounted() {

    },
    methods: {

    },
}
</script>

<style scoped>

</style>