<template>
    <div>
        <b-img
            fluid
            :src="imgUrl"
            alt="Btn Manual"
            rounded
            :style="`width: ${width}%; height: ${height}%`"
        />
    </div>
</template>

<script>
import {
    BImg,
} from 'bootstrap-vue'
export default {
    props: {
        num: {
            type: Number,
        }
    },
    data() {
        return {
            width: 100,
            height: 100
        }
    },
    components: {
        BImg
    },
    computed: {
        imgUrl() {
            return require(`@/assets/images/pages/curtain/${this.num}.png`)
        },
    },
    created() {
        /* window.addEventListener("resize", () => {
            console.log(window.innerWidth)
            if(window.innerWidth > 600) this.width = 45
            else this.width = 100
        }); */
    },
    mounted() {

    },
    methods: {

    },
}
</script>

<style scoped>

</style>